@import url("https://fonts.googleapis.com/css?family=Merriweather");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

#root {
  white-space: pre-line;
  text-align: justify;
}

ul li::marker {
  color: #ff7075;
  content: "► ";
}

ul li:hover::marker {
  color: "#a6494c";
}
